export default {
  ALERT: {
    MyAlerts: 'My Alerts',
    TYPE: 'Type',
    NOMEN: 'Name of entity',
  },
  COMM: {
    TYPE: 'Type',
    DESC: 'description',
  },
  NOTE: {
    NOTE: 'NOTE',
    COMMENTAIRE: 'COMMENT',
  },
  STRATEGIE: {
    NOM: 'STRATEGY NAME',
    NOMPARAMETRE: 'SET NAME',
    EVENT: 'EVENT',
    TXT: 'Transversal teams to which the user belongs: ',
  },
  CARD: {
    BTNACTIF: 'Active',
    BTNDESACTIF: 'Disabled',
    BTNALUMNI: 'Alumni',
    ACCOUNTSTATUS: 'Account status',
    VALID: 'Active',
    ENATT: 'Awaiting registration',
    TAUXCOMP: 'Profile completion rate',
    RDVTERMINE: 'Number of appointments completed',
    Email: 'Email',
    Fonction: 'Works',
    LISTCONGE: 'List of holidays',
    LISTPLANNING: 'Travel schedule list',
    LISTMANAGES: 'List of managed collaborators',
    Presentation: 'Presentation',
    HISTORIQUERDV: 'Appointment history',
    LISTCOMMENT: 'List of notes and comments',
    STRATEGIE: 'Strategies that target this collaborater',
    DISPO: 'Informed availabilities',
    Communities: 'communities',
    Alertes: 'Alerts',
    FORMATIONS: 'Trainings',
    COMPETENCES: 'Skills',
  },
  COLLAB: {
    listeCollaborateursManages: 'List of managed employees',
    ajouterCollaborateur: 'Add employee',
    aucunCollaborateurManage: 'No managed employees',
    aucunCollaborateurSansManager: 'No employees without manager',
    photo: 'Photo',
    nom: 'Last name',
    prenom: 'First name',
    equipe: 'Team',
    service: 'Department',
    site: 'Site',
    btnSave: 'Save',
    CONFIRMATION_RETIRER_MANAGER: 'Are you sure you want to remove this employee from your list of managed persons?',
    EDIT: {
      BTN_CANCEL: 'Cancel'
    }
  },
}
