export default {
  SOUSCRIREMAINTENANT:'Souscrire maintenant',
  SOUSCRIREABONNEMENT: 'Souscrire à un abonnement',
  TEXTABONNEMENT0:'à une de nos formules',
  TEXTABONNEMENT1: "Il semble que vous n'ayez pas d'abonnement en cours.Souscrivez maintenant",
  TEXTABONNEMENT2: "pour débloquer l'accès à tous nos modules et fonctionnalités exclusives.",
  PERONALISERPARAMETRE:
    'Personnalisez votre plateforme en activant ou désactivant vos modules ici, selon votre abonnement et vos besoins.',
  REFRESHAPPLICATION: 'Rafraîchir pour appliquer les changements',
  VOTRECREDITIA: 'Vos crédits IA',
  VOTRECREDITSIGNATURE: 'Vos crédits Signature',
  CREDITS: 'Crédits',
  MSGTITRESUGGESTION:
    "Il s'agit des candidats existants dans la CVthèque, éligibles pour cette offre d'emploi, mais qui n'ont pas postulé.",
  ECHECOPERATION: "  Échec de l'opération.Veuillez réessayer.",
  LIGNEINSERE: 'Ligne insérée',
  LIGNEREJETER: 'Ligne rejetée',
  MODETRAVAIL: 'Mode de travail',
  LOISIRS: 'Loisirs',
  SITE: 'Site',
  SERVICE: 'Service',
  TELEPHONEPRO: 'Téléphone professionnel',
  COMPETENCESPRES: 'Compétences',
  MSGPRES1: 'Ouvrir le chat',
  MSGPRES2: 'Proposer un rendez-vous',
  AUCUNVIDEO: 'Aucune vidéo disponible pour le moment. Veuillez vérifier ultérieurement.',
  AUCUNPRESENTATION: "Aucune description n'est disponible pour le moment.",
  PRESENTATION: 'Présentation',
  CONTRAT: 'Contrat',
  DATEDEBUTCONTRAT: 'Date de début de contrat',
  DATEFINCONTRAT: 'Date de fin de contrat',
  FILTER: 'Filtrer',
  AUCUNMANAGER: 'Aucun manager trouvé',
  EQUIPE: 'Équipe',
  SELECTIONNEREQUIPE: 'Sélectionner une équipe',
  FILTREREQUIPE: 'Filtrer les équipes',
  AUCUNEEQUIPETROUVEE: 'Aucune équipe trouvée',

  MSGEQUIPEINFO:
    'Si vous n’avez pas encore créé vos équipes, vous pouvez affecter ce collaborateur à l’option Non affecté. Une fois vos équipes créées, vous pourrez facilement attribuer ce collaborateur à la bonne équipe',
  REFUSCANDIDAT: 'Refuser le candidat',
  COPIER: 'Copier',
  COPIEREMAIL: "Copier l'adresse email",
  COPIEEMAILSUCCESS: 'Email copié avec succès',
  COPIEQUESTIONS: 'Copier les questions',
  COPIEQUESTIONSSUCCESS: 'Questions copiées avec succès',
  FERMER: 'Fermer',
  SUGGESTIONQUESTIONS: 'Voici une suggestion de 10 questions:',
  QUESTIONSPOSERLORSENTRETIEN: "Questions suggérées à poser aux candidats lors de l'entretien",
  ASSIGNEROFFRE: 'Assigner à une offre',
  EFFECTUERACTION: 'Effectuer une action',
  AUTRESCANDIDATS: 'Autres candidats',
  CANDIDATS: 'Candidats',
  TRIERPARDATE: 'Trier par date',
  PLUSRECENT: 'Plus récent',
  PLUSANCIEN: 'Plus ancien',
  AUCUNCANDIDATTROUVE: "Actuellement, aucun candidat n'a été trouvé.",
  CVSOUMIS: 'Ce CV a été soumis en candidature pour le poste de',
  PASPOSTEAPPLIQUE: 'Pas de poste appliqué',
  COMPTERENDUCV: 'Compte rendu du CV',
  APROPOS: 'À propos de candidat',
  NOMPRENOM: 'Nom et prénom',
  NATIONALITE: 'Nationalité',
  DATENAISSANCE: 'Date de naissance',
  ADRESSE: 'Adresse',
  EMAIL: 'Email',
  NUMTELEPHONE: 'Numéro de téléphone',
  NOM: 'Nom',
  PRENOM: 'Prénom',
  HISTORIQUE: 'Historique',
  CORRESPONDANCEAVEC: 'Correspondance avec le poste',
  RELANCERANALYSE: "Relancer l'analyse par critères de sélection",
  LANCERANALYSE: "Lancer l'analyse par critères de sélection",
  OFFREEMPLOI: "Offre d'emploi",
  ANALYSE: 'Analyse',
  CANDIDATNECORRESPONDPAS: "Le candidat ne correspond pas aux critères de sélection de l'offre d'emploi.",
  ANALYSEPOUSSE: "L'analyse poussée par l'IA n'est disponible que pour le candidat attaché à l'offre.",
  QUESTIONSSUGGEREES: 'Questions suggérées',
  COMPETENCES: 'Ses compétences',
  POSTESUGGERES: 'Postes suggérés',
  PASPOSTESUGGERE: 'Pas de poste suggéré',
  //Notes,Expérience,Formation,Certifications,Les documents de candidat,Ajouter un document,Processus de recrutement
  NOTES: 'Notes',
  EXPERIENCE: 'Expérience',
  FORMATION: 'Formation',
  CERTIFICATIONS: 'Certifications',
  LESDOCUMENTSDECANDIDAT: 'Les documents de candidat',
  AJOUTERUNDOCUMENT: 'Ajouter le document',
  PROCESSUSDERECRUTEMENT: 'Processus de recrutement',
  ASSIGNERcetteoffre: 'Assigner à cette offre',
  SUGGERERCANDIDAT: 'Suggérer des candidats',
  LISTECANDIDATSSUGGERER: 'Liste des candidats suggérés',
  MESCANDIDATS: 'Mes candidats',
  CANDIDATDEJAASSIGNE: 'Ce candidat est déjà assigné à cette offre',
  CANDIDATNONASSIGNE: 'Assigner ce candidat à cette offre',
  RENSEIGNERFROMDOCUMENT: 'Renseigner depuis un document',
  INFOPERSONELLE: 'Informations Personnelles',
  LIEUNAISSANCE: 'Lieu de naissance',
  CODEPOSTAL: 'Code postal',
  COMPLEMENTADRESSE: "Complément d'adresse",
  COORDONNEES: 'Coordonnées',
  DOCUMENTIDENTITEVERSO: "Document d'identité verso",
  DOCUMENTIDENTITERECTO: "Document d'identité recto",
  DOCUMENTSIDENTITE: "Documents d'identité",
  NUMEROSECUSOCIALE: 'Numéro de sécurité sociale',
  VILLE: 'Ville',
  VOIRDOCEXISTANT: 'Voir le document existant',
  REINITIALISER: 'Réinitialiser',
  AUTORISATIONSADMINISTRATIVES: 'Autorisations Administratives',
  MANAGER: 'Manager',
  EMPLOIETPOSITION: 'Emploi et position',
  NOMDUCONTACTURGENT: "Nom du contact en cas d'urgence",
  NUMERODUCONTACTURGENT: "Numéro de contact en cas d'urgence",
  NOMBREDENFANTS: "Nombre d'enfants",
  VEUF: 'Veuf',
  DIVORCE: 'Divorcé',
  MARIE: 'Marié',
  CELIBATAIRE: 'Célibataire',
  SITUATIONFAMILIALE: 'Situation familiale',
  SITUATIONFAMILIALEETURGENCE: 'Situation Familiale et Urgence',
  TELEPHONEPERSONNEL: 'Téléphone personnel',
  TELEPHONEPROFESSIONNEL: 'Téléphone professionnel',
  GENRE: 'Genre',
  HOMME: 'Homme',
  FEMME: 'Femme',
  AUTRE: 'Autre',
  INVALID_FILE_TYPE: 'Type de fichier invalide. Veuillez télécharger un fichier .xls ou .xlsx.',
  VEUUILERIMPORTER: 'Veuillez importer un document',

  COMMUNITY: 'Communauté',
  ENTERPRISE: 'Entreprise',
  DESCRIPTION: 'Description',
  TITLE: 'Titre',
  ACTIONS: 'Actions',
  MEMBER: 'Membre',
  NBR: 'Nombre',
  FNAME: 'Prénom',
  LNAME: 'Nom',
  CREATED_AT: 'Date de Création',
  IMG: 'Image',
  NAME: 'Nom',
  SEARCH: 'Rechercher',
  IN_ANY_FIELD: 'par tout champ',
  BTN_DELETE: 'Supprimer',
  BTN_SAVE: 'Sauvegarder',
  BTN_CONFIRM: 'Confirmer',
  BTN_CANCEL: 'Annuler',
  BTN_ADD: 'Ajouter',
  ALLOWED_FILE_TYPES: 'Types de fichiers autorisés',
  THE_FIELD: 'Le champ ',
  INPUT_VALIDATION: {
    MIN: `doit avoir au moins {{min}} charactères`,
    MAX: `doit avoir au plus {{max}} charactères`,
  },
  SHOWING_ROWS_TABLE: 'Affichage des rangs {{from}} à {{to}} sur {{of}}',
  OPERATION_SUCCESS: 'Opération réussie!',
  OPERATION_FAILED: "L'opération a échouée!",
  OPERATION_WARNING:
    "Ce stratégie est disponible dans une offre supérieure! Merci de passer à l'offre premium pour pouvoir l'utiliser!",
  EXAMPLE: 'Exemple',
  LANG: 'Langue',
  DAY_OF_WEEK: {
    MON: 'Lundi',
    TUE: 'Mardi',
    WED: 'Mercredi',
    THU: 'Jeudi',
    FRI: 'Vendredi',
    SAT: 'Samedi',
    SUN: 'Dimanche',
  },
  ADDRESS: 'Adresse',
  YES: 'Oui',
  NO: 'Non',
  FROM: 'De',
  TO: 'À',
  HEIGHT: 'Longeur',
  WIDTH: 'Largeur',
  COLOR: 'Couleur',
  ERRORS: {
    OCCURED_TRY_AGAIN: 'Une erreur est survenue, merci de réessayer...',
    AI_QUOTA_EXCEEDED: "Votre quota d'IA est dépassé. Merci de nous contacter pour demander une augmentation.",
  },
  VIEW: 'Voir',
  EDIT: 'Modifier',
  DELETE: 'Supprimer',
  CREATE: 'Créer',
  SAVE: 'Enregistrer',
  CANCEL: 'Annuler',
  CLOSE: 'Fermer',
  CONFIRM: 'Confirmer',
  ADD: 'Ajouter',
  ASSIGN: 'Assigner',
  FIND_USER: 'Trouver un collaborateur',
  FIND_USERS: 'Trouver des collaborateurs',
  MONTHS: {
    1: 'Janvier',
    2: 'Février',
    3: 'Mars',
    4: 'Avril',
    5: 'Mai',
    6: 'Juin',
    7: 'Juillet',
    8: 'Août',
    9: 'Septembre',
    10: 'Octobre',
    11: 'Novembre',
    12: 'Décembre',
  },
  ALL: 'Tous',
}
