import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserModel } from 'src/app/modules/auth/_models/user.model';
import { AuthService } from 'src/app/modules/auth/_services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payement-modal',
  templateUrl: './payement-modal.component.html',
  styleUrl: './payement-modal.component.scss'
})
export class PayementModalComponent implements AfterViewInit {
  @ViewChild('pricingTable', { static: false }) pricingTable!: ElementRef;
  currentUser$: any;
  sessionid: any;
  entreprise: any;
  protected authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  stripePublishableKey = environment.stripePublishableKey; // Accédez à la clé API Stripe
  pricingTableId = environment.pricingTableId; // L'ID de la table de prix

  constructor(private authService: AuthService) {
    this.authService.currentUserSubject.subscribe(x => {
      this.currentUser$ = x;
      this.entreprise = this.currentUser$[0]?.ent_id;
    });
    this.sessionid = this.getAuthFromLocalStorage();
  }

  protected getAuthFromLocalStorage(): any {
    try {
      const authData = JSON.parse(localStorage.getItem(this.authLocalStorageToken) || '{}');
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngAfterViewInit(): void {
    // if (this.pricingTable?.nativeElement) {
    //   this.pricingTable.nativeElement.setAttribute('client-reference-id', this.sessionid); 
    //   this.pricingTable.nativeElement.setAttribute('client-reference-id', this.entreprise);
    // }
  }
  
}