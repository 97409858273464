export default {
  'Fiches de poste': 'Fiches de poste',
  'Fiche de poste': 'Fiche de poste',
  'Nouvelle fiche de poste': 'Nouvelle fiche de poste',
  'Assigner à des collaborateurs': 'Assigner à des collaborateurs',
  'Aptitudes et compétences essentielles :': 'Aptitudes et compétences essentielles :',
  'Connaissances essentielles :': 'Connaissances essentielles :',
  'Aptitudes et compétences optionnelles :': 'Aptitudes et compétences optionnelles :',
  'Connaissances optionnelles :': 'Connaissances optionnelles :',
  'Compétences essentielles :': 'Compétences essentielles :',
  'Compétences optionnelles :': 'Compétences optionnelles :',
  'Créer une fiche poste': 'Créer une fiche poste',
  'Modifier la fiche poste': 'Modifier la fiche poste',
  'Supprimer la fiche poste': 'Supprimer la fiche poste',
  'Détails de la fiche poste': 'Détails de la fiche poste',
  'Êtes-vous sûr de vouloir supprimer cette fiche poste ?': 'Êtes-vous sûr de vouloir supprimer cette fiche poste ?',
  'Aucun collaborateur assigné à cette fiche de poste. Tapez dans le champ de recherche pour commencer.':
    'Aucun collaborateur assigné à cette fiche de poste. Tapez dans le champ de recherche pour commencer.',
  'MANAGE_FICHE_POSTE': 'Gérer les fiches de poste',
  'VIEW_FICHE_POSTE_DETAIL': 'Voir le détail de la fiche de poste',
}
