<!-- <div>
  <stripe-pricing-table
    pricing-table-id="prctbl_1QoiofB2egYKku6qw1T1Peac"
    publishable-key="pk_test_51QnHaAB2egYKku6q1S5G2XPWpPvGsrzJbksYju3CF65XxvwYc6KobrfCoXIQNc3jYIlfyF3wgEiftkScx3Zol3aA0080nL5C4r"
  >
  </stripe-pricing-table>
</div> -->

<!-- <stripe-pricing-table
  pricing-table-id="prctbl_1QeJgiBlXF5JQp9ESgzXreUZ"
  publishable-key="pk_live_51QEqXlBlXF5JQp9ERZetyagMzQoKMgLMX14oyXuCTvoi7y5OLn0yGER9GweBR7BkBytPiCAquYBSxYJZd9g6NAnV00NBorVeBi"
>
</stripe-pricing-table> -->
<div *ngIf="pricingTableId && stripePublishableKey">
  <stripe-pricing-table
    [attr.pricing-table-id]="pricingTableId"
    [attr.publishable-key]="stripePublishableKey"
    [attr.redirect-on-success]="SUCCESS_URL">

    
  </stripe-pricing-table>
</div>

