import { environment } from 'src/environments/environment'

/**
 * Version sécurisée de btoa qui supporte les caractères Unicode
 * @param str Chaîne à encoder
 * @returns Chaîne encodée en base64
 */
function safebtoa(str: string): string {
  // Encode la chaîne en UTF-8, puis convertit en base64
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
    return String.fromCharCode(parseInt(p1, 16))
  }))
}

export function imgEncoder(image: string, size: number = 1000, userimg: string | boolean = true) {
  if (image) {
    let buff = safebtoa(
      JSON.stringify({
        bucket: environment.bucket,
        key: image,
        edits: {
          resize: {
            width: size,
            height: size,
            fit: 'inside',
          },
        },
      })
    )
    return environment.cloudFront + buff
  } else {
    return !userimg ? './assets/icons/wp_placeholder.png' : './assets/media/users/blank.png'
  }
}

export function imgEncoderobjet(image: string, size: number = 1000, userimg: string | boolean = true) {
  if (image) {
    let buff = safebtoa(
      JSON.stringify({
        bucket: environment.bucket,
        key: image,
        edits: {
          resize: {
            width: size,
            height: size,
            fit: 'inside',
          },
        },
      })
    )
    return environment.cloudFront + buff
  } else {
    return !userimg ? './assets/icons/wp_placeholder.png' : ''
  }
}

export function imgEncoder2(image: string, size: number = 500, userimg: string | boolean = true) {
  if (image) {
    let buff = safebtoa(
      JSON.stringify({
        bucket: environment.bucket,
        key: image,
        edits: {
          resize: {
            width: size,
            height: size,
            fit: 'inside',
          },
        },
      })
    )
    return environment.cloudFront + buff
  } else {
    return !userimg ? './assets/icons/wp_placeholder.png' : './assets/media/svg/icons/Files/Downloads-folder.svg'
  }
}
export function fileEncoder(file: string) {
  if (file) {
    let buff = safebtoa(
      JSON.stringify({
        bucket: environment.bucket,
        key: file,
      })
    )
    return environment.cloudFront + buff
  } else {
    return './assets/icons/wp_placeholder.png'
  }
}
