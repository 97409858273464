export default {
  MODAL_DICTIONARY: {
    MODAL: 'Gestion des tests',
    HEADER: 'Tests',
    TITLE: "Gérer les paramètres des tests pour les offres d'emploi.",
    ADD: 'Ajouter un test',
    SELECT: 'Sélectionner tout',
    DELETE: 'Supprimer',
    EDIT: 'Modifier',
    RESPONSE: 'Résponse',
    DIFFICULTY: 'Difficulté',
    NBQ: 'Nombre de questions',
    SKILL: 'Compétence',
    DETAIL: 'Détails',
    DETAILEX: 'Cacher les Détails',
    FAILED:
      "Veuillez spécifier le nom du test, le niveau de difficulté, la compétence, et assurez-vous qu'il y ait au moins une question.",
    GFAILED:
      'Veuillez spécifier le nom du test, le niveau de difficulté, la langue et assurez-vous que le nombre de questions soit entre 1 et 20.',
    PHEADER: 'Ajouter les paramètres du test',
    PPARAM: 'pramètres',
    PNAME: 'Nom du test',
    PNAMEPH: 'Nom du test',
    LANGUAGE: 'Langue',
    PLANGUAGE: 'Choisir une langue',
    PFA: 'Facile',
    PDIF: 'Difficile',
    PMO: 'Moyen',
    PNBQ: 'Nombre de questions à générer',
    PNBQPH: 'Entrez un nombre entre 1 et 20',
    INSTRUCTIONS: 'Instructions',
    PINSTRUCTIONS: 'Générer des QCMs',
    PADD: 'Ajouter une question',
    PGEN: 'Générer des questions',
    CANCEL: 'Annuler',
    PSAV: 'Sauvegarder',
    PSKILL: 'Chercher une compétence',
    HEADERMAIL: 'Gestion des Emails',
    POOLTITLE: 'Nom',
    SEARCH: 'rechercher',
    RECCOM: "Il est recommandé de ne pas modifier les questions générées par l'IA !",
    WARN1: 'Veuillez sauvegarder les questions avant de procéder.',
    WARN2: "Veuillez remplir tous les champs et assurez-vous qu'il y ait au moins une question.",
    WARN3: 'Pas assez de crédits pour générer des questions.',
    PERRNUM: 'Veuillez entrer un nombre entre 1 et 20.',
    DESCRIPT: 'Description à afficher',
    CAT: 'Catégorie',
    PCAT: 'Sélectionner une catégorie',
    PDESCRIPT: 'Écrire une description pour le test',
    ADDIM: 'Ajouter une image ',
    DESCIM: 'Description à afficher',
    ERRDEL:
      'Ce test est actuellement utilisé et ne peut pas être supprimé. Pour procéder à la suppression, veuillez le retirer des évaluations : ',
    ISA: "Attribuer l'image à la réponse",
    IMDESCHOVER: "Si la case est sélectionnée, l'image sera affichée au-dessus du champ de réponse.",
  },

  MODAL_TEST: {
    MODECLASSIQUE: 'Mode Classique',
    MODEKANBAN: 'Mode Kanban',
    CANDIDATCOMPETENCE: 'Candidat compétences',
    DATESOUMISSION: 'Date de soumission de candidature',
    TESTENVOYEPASPASSE: 'Evaluation envoyé mais pas encore passé',
    NOMBREEMAILSENVOYE: "Nombre d'emails envoyés",
    TESTENVOYEPASSE: 'Evaluation envoyé et passé',

    HEADER: 'Liste des évaluations',
    ADD: 'Ajouter une évaluation',
    NAME: 'Nom',
    DUR: 'Durée',
    NUM: 'Nombre de Questions',
    SEARCH: 'Rechercher',
    VIEW: 'Aperçu des questions',
    QUES: 'Aucune question disponible',
    SEL: 'Sélectionner un test',
    REM: 'Supprimer',
    PHEADER: "Ajouter les paramètres de l'évaluation",
    ERR: "Veuillez remplir tous les champs, assurez-vous qu'au moins un test est inclus, et confirmez que la durée est supérieure à 0.",
    DADD: 'Nombre de questions',
    ENAME: "Saisir le nom de l'évaluation",
    EDUR: "Saisir la durée de l'évaluation",
    ENUM: 'Saisir le nombre de questions',
    SELECT_DICTIONARY: 'Sélectionner un test',
    PERRNUM: 'Veuillez entrer un nombre entre 1 et',
    ERRDUR: 'Veuillez entrer une durée supérieure à 0.',
    SIM: 'Simuler',
    SIML: 'Simulation en cours',
    SIMC: 'Terminer',
    ERRDEL:
      'Cette évaluation est actuellement utilisée et ne peut pas être supprimée. Pour procéder à la suppression, veuillez la retirer des offres : ',
  },
  MODAL_OFFER: {
    ASSO: 'Associer une évaluation',
    ASSOE: "Associer un envoi automatique de l'évaluation à une étape",
    AUC: 'Aucune évaluation',
    AUCS: 'Aucune étape',
    VAL: 'Veuillez sélectionner une évaluation valide.',
    SEND: 'Envoyer une évaluation',
    RSEND: "Renvoyer l'évaluation",
    RES: 'Résultat de l’évaluation',
    CRES: 'Résultat du candidat',
    INF: 'Informations sur le candidat',
    NAME: 'Nom',
    QUES: 'Nombre de questions',
    BR: 'Répartition visuelle',
    SS: 'Score par compétence',
    SP: 'Score par test',
    SKILL: 'Compétence',
    POOL: 'Tests',
    DRES: 'Résultats détaillés',
    CA: 'Réponse du candidat',
    CRA: 'Réponse attendue',
    EXIT: 'QUITTER',
    CORR: 'Questions correctes',
    QCORR: 'Correct',
    QINC: 'Incorrect',
    SELECT_TEST: 'Sélectionner une évaluation',
    SELECT_STEP: 'Sélectionner une étape',
    ERT: 'Une erreur inattendue s’est produite.',
    TES: 'Evaluation envoyé avec succès.',
    TED: 'Evaluation déjà envoyé.',
    TS: 'Temps passé par le candidat',
    NOTES: "Les compétences professionnelles du candidat, telles qu'indiquées sur son CV",
    ANALYSIS: 'Analyse',
    RECORD: 'Enregistrement vidéo',
  },
  MODAL_CONFIRM: {
    MESSAGE:
      "Attention!\nTous les résultats et les progrès seront perdus.\nÊtes-vous sûr de vouloir renvoyer l'évaluation à ",
    MESSAGEE: 'Veuillez remplir les champs requis pour envoyer l’email à ',
    MESSAGEEET: 'Veuillez remplir les champs requis pour envoyer l’email à tous les candidats.',
    ANN: 'Annuler',
    SUB: 'Envoyer',
    NEXP: 'Nom de l’expéditeur',
    EEXP: 'Email de l’expéditeur',
    NPH: 'Entrez le nom de l’expéditeur',
    EPH: 'Entrez l’email de l’expéditeur',
  },
  MODAL_CHART: {
    DOWNLOAD: 'Télécharger en',
    OTH: 'Non défini',
  },
  MODAL_DICTIONARY_HELP: {
    HEAD: 'Aide pour la gestion des tests',
    BODY: `
    <ul>
  <h5>Informations Obligatoires :</h5>
  <ul>
    <li>Nom du test (Utilisé pour générer des questions)</li>
    <li>Niveau de difficulté (Valeur initiale : Facile)</li>
    <li>Au moins une carte de question</li>
  </ul>
  <br />
  <h5>Instructions :</h5>
  <li>Utilisez la section gauche pour saisir les détails du test, y compris le nom, la compétence et la catégorie.</li>
  <li>Définissez le niveau de difficulté à l'aide des boutons fournis.</li>
  <li>Sélectionnez une catégorie pour votre test.</li>
  <li>Ajoutez une brève description à votre test.</li>
  <br />
  <h5>Pour Générer des Questions avec l'IA :</h5>
  <li>Spécifiez le nombre de questions (1–20) et sélectionnez une langue. (Il est recommandé d'utiliser une langue fixe pour toutes les questions)</li>
  <li>(Optionnel) Ajoutez des instructions spécifiques pour que l'IA adapte la sortie à vos besoins.</li>
  <li>Cliquez sur <strong>"Générer des questions"</strong> pour créer les questions.</li>
  <br />
  <h5>Revoir et Sauvegarder :</h5>
  <li>Utilisez la section droite pour examiner les questions générées et ajouter des questions personnalisées en cliquant sur <strong>"Ajouter une question"</strong>.</li>
  <li>Cliquez sur le bouton <strong>"Sauvegarder"</strong> pour ajouter le test au système.</li>
  <h5>Notes Importantes :</h5>
  <li>(Optionnel) Vous pouvez ajouter jusqu'à quatre images par question.</li>
  <li>Les images et leurs descriptions correspondantes sont uniquement à titre de référence et ne seront pas prises en compte dans l'évaluation de la réponse du candidat. Assurez-vous que le champ de réponse contient la réponse souhaitée.</li>
</ul>
    `,
    HIDE: 'Cacher',
  },
}
