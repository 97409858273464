import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from './modules/auth/_services/auth.guard'
import { ExtAuthPageSuccessComponent } from './modules/auth/ext-auth-page-success/ext-auth-page-success.component'
import { SurveyFillComponent } from './modules/qsp/survey-fill/survey-fill.component'
import { PayementModalComponent } from './_metronic/partials/content/dashboards/payement-modal/payement-modal.component'

export const routes: Routes = [
  {
    path: 'recruit/skill-test',
    loadChildren: () => import('./modules/skill-tests/skill-tests.module').then(m => m.SkillTestsModule),
  },
  {
    path: 'survey/fill/:targetId/:surveyId',
    component: SurveyFillComponent,
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./modules/errors/errors.module').then(m => m.ErrorsModule),
  },
  {
    path: 'ext-auth-success',
    component: ExtAuthPageSuccessComponent,
  },
  { path: 'payement-abonnement',    canActivate: [AuthGuard],
    component: PayementModalComponent }, // Route for payment

  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/layout.module').then(m => m.LayoutModule),
  },
  { path: '**', redirectTo: 'error/404' },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
