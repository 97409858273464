export default {
  DELETE: {
    TITLETACHE: 'Delete task',
    DESC_1TACHE: 'Task deletion...',
    DESC_2TACHE: 'Are you sure you want to permanently delete this task?',
    BTN_DELETETACHE: 'Delete',
    BTN_CANCELTACHE: 'Cancel',
  },







}