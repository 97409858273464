export default {
  ALERT: {
    MyAlerts: 'Mes Alertes',
    TYPE: 'Type',
    NOMEN: "Nom de l'entité",
  },
  COMM: {
    TYPE: 'Type',
    DESC: 'La description',
  },
  NOTE: {
    NOTE: 'NOTE',
    COMMENTAIRE: 'COMMENTAIRE',
  },
  STRATEGIE: {
    NOM: 'NOM STRATEGIE',
    NOMPARAMETRE: 'NOM PARAMETRÉ',
    EVENT: 'ÉVÉNEMENT',
    TXT: 'Les équipes Transverses auxquelles appartient le user: ',
  },
  CARD: {
    BTNACTIF: 'Actif',
    BTNDESACTIF: 'Désactivé',
    BTNALUMNI: 'Alumni',
    ACCOUNTSTATUS: 'Statut du compte',
    VALID: 'Actif',
    ENATT: "En attente d'inscription",
    TAUXCOMP: 'Taux de completion du profil',
    RDVTERMINE: 'Nombre de rendez-vous terminés',
    Email: 'Email',
    Fonction: 'Fonction',
    LISTCONGE: 'Liste des congés',
    LISTPLANNING: 'List de planning de déplacement',
    LISTMANAGES: 'Liste des collaborateurs managés',
    Presentation: 'Présentation',
    HISTORIQUERDV: 'Historique des rendez-vous',
    LISTCOMMENT: 'Liste des notes et commentaires',
    STRATEGIE: 'Les Stratégies qui ciblent ce collaborateur',
    DISPO: 'Disponibilités renseignées',
    Communities: 'Communautés',
    Alertes: 'Alertes',
    FORMATIONS: 'Formations',
    COMPETENCES: 'Compétences',
  },
  COLLAB: {
    listeCollaborateursManages: 'Liste des collaborateurs managés',
    ajouterCollaborateur: 'Ajouter un collaborateur',
    aucunCollaborateurManage: 'Aucun collaborateur managé',
    aucunCollaborateurSansManager: 'Aucun collaborateur sans manager',
    photo: 'Photo',
    nom: 'Nom',
    prenom: 'Prénom',
    equipe: 'Équipe',
    service: 'Service',
    site: 'Site',
    btnSave: 'Enregistrer',
    CONFIRMATION_RETIRER_MANAGER: 'Êtes-vous sûr de vouloir retirer ce collaborateur de votre liste de personnes managées?',
    EDIT: {
      BTN_CANCEL: 'Annuler'
    }
  },
}
