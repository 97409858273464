import { HttpClient, HttpEvent, HttpParams, HttpRequest } from '@angular/common/http'
import { Inject, Injectable, OnDestroy } from '@angular/core'
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { environment } from '../../../../environments/environment'
import { ITableState, TableResponseModel, TableService } from '../../../_metronic/shared/crud-table'
import { baseFilter } from '../../../common/helpers/http-extenstions'
import { User } from '../_models/user.models'

@Injectable({
  providedIn: 'root',
})
export class UserService extends TableService<User> implements OnDestroy {
  currentUserSubject: BehaviorSubject<User>
  isLoadingSubject: BehaviorSubject<boolean>

  get currentUserValue(): User {
    return this.currentUserSubject.value
  }

  set currentUserValue(user: User) {
    this.currentUserSubject.next(user)
  }

  // protected authLocalStorageToken = ${environment.appVersion}-${environment.USERDATA_KEY};
  API_URL = `${environment.apiUrl}/todo/`
  API_URLSTRATEGY = `${environment.apiUrl}/strategie/getstrategie`
  API_URLALLSTRATEGY = `${environment.apiUrl}/strategie/getstrategieforajout`
  API_URLSTRATEGYEQ = `${environment.apiUrl}/strategie/getstrategieequipes`
  API_URLSTRATEGYSER = `${environment.apiUrl}/strategie/getstrategieservices`
  API_URLSTRATEGYSite = `${environment.apiUrl}/strategie/getstrategiesites`
  API_URLHobies = `${environment.apiUrl}/todo/gethobbies`
  API_URLALLMEMBERS = `${environment.apiUrl}/todo/allusers`
  API_URL_USER_FOR_SITE = `${environment.apiUrl}/site/getusersforSite`
  API_URLMEMBERS = `${environment.apiUrl}/equipe/getMembrsfor_Equipetransverse`
  API_URLMEMBERSPIC = `${environment.apiUrl}/equipe/getMembrsPicfor_Equipetransverse`

  API_URLHobiesForUser = `${environment.apiUrl}/todo/gethobbies_for_user_cncte`
  API_URLLISTCONGE = `${environment.apiUrl}/todo/getmyconge`
  API_URLPr = `${environment.apiUrl}/todo/modifierpresentation`
  API_URLLISTPlanning = `${environment.apiUrl}/deplacement/getdeplacements`
  API_URLGETSTATUS = `${environment.apiUrl}/get_status_job`
  API_URLHISTORIQUEUSER = `${environment.apiUrl}/todo/historiqueUser`
  API_URLGETContrats = `${environment.apiUrl}/contrat/get_contrats`

  constructor(@Inject(HttpClient) http) {
    super(http)
    this.isLoadingSubject = new BehaviorSubject<boolean>(false)
    this.currentUserSubject = new BehaviorSubject<User>(undefined)
  }
  getlistUsersManager(uuid_user: any) {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid).set('user_uuid', uuid_user)

    return this.http.get<any>(`${environment.apiUrl}/todo/getUsersManager`, { params })
  }
  find(tableState: ITableState, TYPE_COMPTE: any, idsite: any, list: any): Observable<TableResponseModel<User>> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams()
      .set('sessionid', sessionid)
      .set('TYPE_COMPTE', TYPE_COMPTE)
      .set('idsite', idsite)
      .set('list', list)
    return this.http.get<any>(`${this.API_URL}`, { params }).pipe(
      map((response: User[]) => {
        const filteredResult = baseFilter(response, tableState)
        const result: TableResponseModel<User> = {
          items: filteredResult.items,
          total: filteredResult.total,
        }
        return result
      })
    )
  }

  findStrategie(tableState: ITableState): Observable<TableResponseModel<any>> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any>(`${this.API_URLSTRATEGY}`, { params }).pipe(
      map((response: any[]) => {
        const filteredResult = baseFilter(response, tableState)
        const result: TableResponseModel<any> = {
          items: filteredResult.items,
          total: filteredResult.total,
        }
        return result
      })
    )
  }
  getPermissins() {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    return this.http.get<any>(`${environment.apiUrl}/permission/get-permission`, { params })
  }
  findAllStrategie(): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()
    let resEq = localStorage.getItem('resequipe')
    let resServ = localStorage.getItem('resservice')
    let resSite = localStorage.getItem('ressite')
    let role = localStorage.getItem('role')
    const params = new HttpParams()
      .set('sessionid', sessionid)
      .set('resEq', resEq)
      .set('resServ', resServ)
      .set('resSite', resSite)
      .set('role', role)
    return this.http.get<any>(`${this.API_URLALLSTRATEGY}`, { params }).pipe()
  }
  ///when you click on new strategy
  //la liste de strategie pour responsable
  findAllStrategieforresponsable(resEq: any, resServ: any, resSite: any, role: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()
    const params = new HttpParams()
      .set('sessionid', sessionid)
      .set('resEq', resEq)
      .set('resServ', resServ)
      .set('resSite', resSite)
      .set('role', role)
    return this.http.get<any>(`${this.API_URLALLSTRATEGY}`, { params }).pipe()
  }
  findStrategieEq(): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any>(`${this.API_URLSTRATEGYEQ}`, { params }).pipe()
  }
  findStrategieSer(): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any>(`${this.API_URLSTRATEGYSER}`, { params }).pipe()
  }

  findStrategieSite(): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any>(`${this.API_URLSTRATEGYSite}`, { params }).pipe()
  }
  deleteItems(ids: number[] = []): Observable<any> {
    const tasks$ = []
    ids.forEach(id => {
      tasks$.push(this.delete(id, null))
    })
    return forkJoin(tasks$)
  }

  //photo
  fileUpload(file: FormData) {
    let sessionid = this.getAuthFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid)
    let urlphoto = `${environment.apiUrl}/todo/api/upload?sessionid=${sessionid}`

    return this.http.post(urlphoto, file)
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe())
  }

  //compress
  // in bytes, compress images larger than 1MB
  fileSizeMax = 1 * 1024 * 1024
  // in pixels, compress images have the width or height larger than 1024px
  widthHeightMax = 1024
  defaultWidthHeightRatio = 1
  defaultQualityRatio = 0.7

  compress(file: File): Observable<File> {
    const imageType = file.type || 'image/png' || 'image/jpeg'
    const reader = new FileReader()
    reader.readAsDataURL(file)

    return Observable.create(observer => {
      // This event is triggered each time the reading operation is successfully completed.
      reader.onload = ev => {
        // Create an html image element
        const img = this.createImage(ev)
        // Choose the side (width or height) that longer than the other
        const imgWH = img.width > img.height ? img.width : img.height

        // Determines the ratios to compress the image
        let withHeightRatio = imgWH > this.widthHeightMax ? this.widthHeightMax / imgWH : this.defaultWidthHeightRatio
        let qualityRatio = file.size > this.fileSizeMax ? this.fileSizeMax / file.size : this.defaultQualityRatio

        // Fires immediately after the browser loads the object
        img.onload = () => {
          const elem = document.createElement('canvas')
          // resize width, height
          elem.width = img.width * withHeightRatio
          elem.height = img.height * withHeightRatio

          const ctx = <CanvasRenderingContext2D>elem.getContext('2d')
          ctx.drawImage(img, 0, 0, elem.width, elem.height)
          ctx.canvas.toBlob(
            // callback, called when blob created
            blob => {
              observer.next(
                new File([blob], file.name, {
                  type: imageType,
                  lastModified: Date.now(),
                })
              )
            },
            imageType,
            qualityRatio // reduce image quantity
          )
        }
      }

      // Catch errors when reading file
      reader.onerror = error => observer.error(error)
    })
  }
  private createImage(ev) {
    let imageContent = ev.target.result
    const img = new Image()
    img.src = imageContent
    return img
  }

  findLink(): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    let API_URLLINK = `${environment.apiUrl}/todo/lienInscription?sessionid=${sessionid}`
    return this.http.post<any>(API_URLLINK, { params })
  }

  findLinkforinscritequipe(ide: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    let API_URLLINK = `${environment.apiUrl}/todo/lienInscription`
    let body = { ide }
    return this.http.post<any>(API_URLLINK, body, { params })
  }
  findHobies(lang: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid).set('lang', lang)
    return this.http.get<any>(`${this.API_URLHobies}`, { params }).pipe()
  }
  findAllMembers(): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any>(`${this.API_URLALLMEMBERS}`, { params }).pipe()
  }

  findAllManager(id: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage();
  
    let params = new HttpParams().set('sessionid', sessionid);
  
    // Only add 'id' if it exists
    if (id !== null && id !== undefined) {
      params = params.set('id', id);
    }
  
    return this.http.get<any>(`${this.API_URLALLMEMBERS}`, { params }).pipe();
  }
  
  findUserForSite(id: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid).set('ids', id)
    return this.http.get<any>(`${this.API_URL_USER_FOR_SITE}`, { params }).pipe()
  }
  findMember(id: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid).set('id_equipe_trans', id)
    return this.http.get<any>(`${this.API_URLMEMBERS}`, { params }).pipe()
  }
  findMemberPic(id: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid).set('id_equipe_trans', id)
    return this.http.get<any>(`${this.API_URLMEMBERSPIC}`, { params }).pipe()
  }
  findHobiesForUserConnecte(lang: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid).set('lang', lang)

    return this.http.get<any>(`${this.API_URLHobiesForUser}`, { params })
  }

  createPr(item: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.post<any>(this.API_URLPr, item, { params }).pipe(
      catchError(err => {
        console.error('CREATE ITEM', err)
        window.location.reload()
        return of({ ids: undefined })
      })
    )
  }
  //consume edit conge
  updateConge(item: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    let dt_debut = item.dt_debut
    let debut_ms = item.debut_ms
    let dt_fin = item.dt_fin
    let fin_ms = item.fin_ms

    let body = { dt_debut, debut_ms, dt_fin, fin_ms }
    return this.http.post<any>(`${this.API_URLUPDATECONGE}`, body, { params })
  }
  deleteConge(item: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()
    let dt_debut = item.dt_debut
    let debut_ms = item.debut_ms

    const params = new HttpParams().set('sessionid', sessionid).set('dt_debut', dt_debut).set('debut_ms', debut_ms)

    return this.http.delete(`${this.API_DELETE_CONGE}`, { params })
  }

  listConge(): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    return this.http.get<any>(`${this.API_URLLISTCONGE}`, { params })
  }
  listPlanning(): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    return this.http.get<any>(`${this.API_URLLISTPlanning}`, { params })
  }
  getStatusJobs(id: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid).set('uuid_jobs', id)
    return this.http.get<any>(`${this.API_URLGETSTATUS}`, { params }).pipe()
  }
  getEntrepriseAbonnement() {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    return this.http.get<any>(`${environment.apiUrl}/abonnement/get_OptionAbonnement`, { params })
  }
  historiqueUser(user_uuid): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid).set('user_uuid', user_uuid)
    return this.http.get<any>(`${this.API_URLHISTORIQUEUSER}`, { params }).pipe()
  }
  //get  listes des contrat de user
  getContrats(id: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid).set('uuid', id)
    return this.http.get<any>(`${this.API_URLGETContrats}`, { params }).pipe()
  }
  API_URL_UPLOAD = `${environment.apiUrl}/questions/upload`
  API_URL_DECRYPT = `${environment.apiUrl}/questions/decrypt`

  upload(file: File): Observable<HttpEvent<{}>> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    const formData = new FormData()
    formData.append('file', file)

    const req = new HttpRequest('POST', this.API_URL_UPLOAD, formData, {
      params,
      reportProgress: true,
      // responseType: 'json'
    })

    return this.http.request(req)
  }

  decryptFile(filename: string) {
    let sessionid = this.getAuthFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid).set('filename', filename)
    return this.http.get(`${environment.apiUrl}/questions/decrypt`, { params, responseType: 'blob' })

  }
}
