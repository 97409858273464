export const environment = {
  enableLogginApiCalls: true,
  production: false,
  // src/environments/environment.ts

  stripePublishableKey:'pk_test_51QnHaAB2egYKku6q1S5G2XPWpPvGsrzJbksYju3CF65XxvwYc6KobrfCoXIQNc3jYIlfyF3wgEiftkScx3Zol3aA0080nL5C4r',
  pricingTableId: 'prctbl_1QoiofB2egYKku6qw1T1Peac',
  appVersion: 'v723demo1',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: window.location.origin.includes('localhost') ? 'http://localhost:3001' : 'https://devapiapp.wemiam.co',
  emyeChatbotApiUrl: window.location.origin.includes('localhost') ? 'http://localhost:3005' : 'https://devemye.emye.eu',
  bucket: 'dev-wemiamht',
  cloudFront: 'https://d3k5hq8q1ja9j8.cloudfront.net/',
  apiUrlFront: window.location.origin,
  jitsiServer: 'https://rtc.wemiam.co/',
  socketUrl: 'https://devtchat.wemiam.co',
  supersetServer: 'https://devreport.wemiam.co',
  apiUrlQSP: 'https://devapiqsp.wemiam.co',
  //mani env
  baseUrl: 'https://devquiz.wemiam.co/quiz1/?rest_route=/hidelogin/v1/autologin',
  redirectUrl: 'https://devquiz.wemiam.co/quiz',
  newPassWsUrl: 'https://www.wemiam.co/wp-json/wp/v2/users/',
  AUTH_KEY: 'wsYs2iNU74DPbxsnnV50dwnCtLb0XJO5',
  simpleUrl: 'https://devquiz.wemiam.co/?rest_route=/hidelogin/v1/auth',
  resetpassUrl: 'https://devquiz.wemiam.co/?rest_route=/hidelogin/v1/user/reset_password',
  registerUser: 'https://devquiz.wemiam.co/?rest_route=/hidelogin/v1/users',
  supersetDashIds: {
    superset: '27d685c5-bc33-45c5-b53d-7488b79ed0bf',
    surveys: '924427f7-fdd3-4e96-be44-19ddae147fed',
    flexi: 'fafbdd11-c74d-40a4-92e8-dad1ff7ae132',
    onb: '94f3fef9-269b-464e-b294-c5c12689e62a',
    quiz: '7137e0af-eaeb-41f8-9909-0187fa0fb4a8',
    poll: '1a829bad-7be1-4ac3-b4b2-c32d978765a6',
    strategie: '7176c62d-1c59-4b1a-87af-2a7850a15089',
    formation: 'df887ebb-ceae-44ba-84f1-17996fded488',
    evenement: '928e701e-024f-462c-9a39-53ff01d83c8f',
    newsletter: 'e592af9e-5341-4d55-8604-9c13d1c1b3e8',
    question: '68aec1ca-6318-4d1e-91e1-2fe53abf04bc',
    materiel: '00436d21-a77d-4a31-a2c5-1fa359ddd65a',
    boiteIdee: 'dc0de762-e48f-4f06-9041-f1d2a8cb6fe9',
    suivitemps: '54ca6dc8-11de-4377-8725-028b0f2ea37b',
    dashboard_ia: '05baa3ca-1f85-4435-b718-cfdf3688f06f',
    entretien: '53790fec-8129-4dd3-bb8f-473fa76eda9c',
    recrutement: '97cf8bdd-3ba9-49ac-b014-33cd2b3d4405',
    listes: 'c68b07e7-aaeb-4d99-af0f-1ac82806cf2a',
  },
  maxFileSizeNotSubsc: 5 * (1024 * 1024),
  maxFileSizeSubsc: 20 * (1024 * 1024),
  pageCountNotSubsc: 30,
  pageCountSubsc: 100,
  nbrDocNotSubsc: 3,
  recrutement_tab_position: 12,
  apiUrlFrontPortail: window.location.origin.includes('localhost')
    ? 'http://localhost:4300'
    : 'https://devhi.emyehr.com',
}
