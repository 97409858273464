export default {
  RETIRERDELAISTEDESPERSONNESMANAGEES: 'Retirer de la liste des personnes managées',
  VoirLaFicheDetailleeDuCollaborateur: 'Voir la fiche détaillée du collaborateur',
  RETIRER: 'Retirer',
  ACTIONS: 'Actions',
  COMMUNITY: 'Communauté',
  ENTERPRISE: 'Entreprise',
  MEMBER: 'Membre',
  DESCRIPTION: 'Description',
  TITLE: 'Titre',
  NBR: 'Nombre',
  FNAME: 'Prénom',
  LNAME: 'Nom',
  CREATED_AT: 'Créé le',
  IMG: 'Image',
  NAME: 'Nom',
  SEARCH: 'Rechercher',
  IN_ANY_FIELD: "dans n'importe quel champ",
  BTN_DELETE: 'Supprimer',
  BTN_SAVE: 'Enregistrer',
  BTN_CONFIRM: 'Confirmer',
  BTN_CANCEL: 'Annuler',
  BTN_ADD: 'Ajouter',
  ALLOWED_FILE_TYPES: 'Types de fichiers autorisés',
  THE_FIELD: 'Le champ ',
  INPUT_VALIDATION: {
    MIN: ` doit comporter au moins {{min}} caractères`,
    MAX: ` doit comporter au plus {{max}} caractères`,
  },
  SHOWING_ROWS_TABLE: 'Affichage des lignes {{from}} à {{to}} sur {{of}}',
  OPERATION_SUCCESS: 'Opération réussie',
  OPERATION_FAILED: 'Opération échouée !',
  OPERATION_WARNING:
    "Cette stratégie est disponible dans un plan premium ! Veuillez passer au plan premium pour l'utiliser !",
  REFRESH: 'Actualiser la liste',
  REFRESH_SUCCESS: 'Actualisation réussie',
  REFRESH_ERROR: "Erreur lors de l'actualisation",
  OPENS_NEW_TAB: "S'ouvre dans un nouvel onglet",

  SKILL_TYPE_REQUIRED: 'Le type de compétence est obligatoire',
  SKILL_INVALID: 'La compétence sélectionnée est invalide',
  SKILL_ADDED_ESSENTIAL: 'Compétence ajoutée aux compétences essentielles',
  SKILL_ADDED_OPTIONAL: 'Compétence ajoutée aux compétences optionnelles',
  SKILL_REMOVED: 'Compétence retirée',
  SKILL_UPDATE_REMINDER: "Pensez à mettre à jour l'offre ou à la régénérer",
  SKILL_ALREADY_EXISTS_IN_ESSENTIAL: 'La compétence existe déjà dans les compétences essentielles',
  SKILL_ALREADY_EXISTS_IN_OPTIONAL: 'La compétence existe déjà dans les compétences optionnelles',
  SKILL_ADDED_SUCCESSFULLY: 'Compétence ajoutée avec succès',

  JOB_OFFER_UPDATED: "Offre d'emploi mise à jour avec succès",
  JOB_OFFER_UPDATE_ERROR: "Une erreur s'est produite lors de la mise à jour de l'offre",
  JOB_OFFER_UPDATED_SUCCESSFULLY: "Offre d'emploi mise à jour avec succès",

  DESCRIPTION_ENTREPRISE: "Description de l'entreprise",
  DESCRIPTION_ENTREPRISE_PLACEHOLDER: "Métier de l'entreprise",
  DESCRIPTION_ENTREPRISE_TOOLTIP:
    "C'est une courte description de votre entreprise pour aidé Emye a mieux vous connaitre",
  ERROR_UPDATING_JOB_OFFER: "Une erreur s'est produite lors de la mise à jour de l'offre",

  SUCCESS: 'Succès',
  ERROR: 'Erreur',
  WARNING: 'Avertissement',
  INFO: 'Information',

  EXAMPLE: 'Exemple',
  LANG: 'Langue',

  // Nouvelles clés de traduction pour la fonctionnalité d'édition des questions
  EDIT: 'Modifier',
  CANCEL: 'Annuler',
  SAVE: 'Enregistrer',
  ADD: 'Ajouter',
  All: 'Tous',
  FEMYE: 'Assistant IA RH',
  FNOAH: 'Coach IA RH',
  FJULIE: 'Compagnon IA Juridique',
  FTHOMAS: 'Compagnon IA CSRD',
  FALAIN: 'Compagnon IA Support',
  FSECUREGPT: 'Assistant IA Général',
  REPMESSUCCESS: 'Message signalé avec succès',
  REPMESFAILED: 'Échec du signalement du message',
  REPSEND: 'Envoyer',
  // REPCANCEL: 'Annuler',
  // REPTITLE: 'Signaler un message',
  // REPPH: 'Veuillez décrire la raison du signalement de ce message...',
  // DESCRIPTION_ENTREPRISE: "Description de l'entreprise",
  // DESCRIPTION_ENTREPRISE_PLACEHOLDER: "Secteur d'activité de l'entreprise",
  // DESCRIPTION_ENTREPRISE_TOOLTIP: "Il s'agit d'une brève description de votre entreprise pour aider Emye à mieux comprendre vos besoins.",

  ADDNEWQUESTION: 'Ajouter une nouvelle question',
  ENTERQUESTION: 'Saisir la question',
  QUESTIONSSAVEDSUCCESS: 'Questions enregistrées avec succès',
  QUESTIONADDEDSUCCESS: 'Question ajoutée avec succès',
  QUESTIONDELETEDSUCCESS: 'Question supprimée avec succès',
  QUESTIONEDITEDSUCCESS: 'Question modifiée avec succès',
  ERRORLOADINGQUESTIONS: 'Erreur lors du chargement des questions',
  ERRORSAVINGQUESTIONS: 'Erreur lors de la sauvegarde des questions',
  ERRORADDINGQUESTION: "Erreur lors de l'ajout de la question",
  ERRORDELETINGQUESTION: 'Erreur lors de la suppression de la question',
  ERROREDITINGQUESTION: 'Erreur lors de la modification de la question',
  MISSINGPARAMETERS: 'Paramètres manquants',

  REPCANCEL: 'Annuler',
  REPTITLE: 'Signaler le message',
  REPPH: 'Veuillez décrire la raison du signalement de ce message...',
  TEXTIA:
    "Ces documents sont générés par l'IA et sont fournis à titre informatif uniquement. Veuillez examiner leur contenu et les adapter avec l'aide d'un professionnel avant utilisation.",
  NOMMODELE: 'Nom du modèle',
  TEXTINFOPERSONALISER:
    "Vous n'avez pas à mettre de caractères spéciaux pour les noms et prénoms, vous pouvez les copier directement et l'IA remplacera automatiquement les champs avec les bonnes informations.",
  AJOUTMODELEPERSONALISER: 'Ajouter un modèle personnalisé',
  ENREGISTREMODEL: 'Enregistrer',
  FORMATIONMENU: 'Formation',
  FOLLOW: 'Suivre',
  PLAN: 'Plan',
  TEMPLATES: 'Modèles',
  DOCUMENT_TO_SIGN: 'Document à signer',
  URLNONVALIDE: 'URL non valide.',
  RECRUTEMENTTEXT1: "Logo de l'entreprise",
  RECRUTEMENTTEXT2: 'Vidéo de présentation',
  RECRUTEMENTTEXT3: "Description de l'entreprise",
  RECRUTEMENTTEXT4: 'Site web',
  RECRUTEMENTTEXT5: 'Mots-clés',
  RECRUTEMENTTEXT6: 'Bannière',
  RECRUTEMENTTEXT7: 'Autre',
  RECRUTEMENTTEXT8: 'Enregistrer',
  EMAIL_ACCEPTANCE_TEMPLATE: "Modèle d'email d'acceptation de candidature",
  CUSTOMIZE: 'Personnaliser',
  EMAIL_REJECTION_TEMPLATE: "Modèle d'email de rejet de candidature",
  EMAIL_INTERVIEW_INVITATION_TEMPLATE: "Modèle d'email d'invitation à un entretien",
  EMAIL_TEST_SUBMISSION_TEMPLATE: "Modèle d'email de soumission de test",
  PERSONALISERLIENACCES: "Personnaliser vos liens d'accès",
  MSGPERSONALISERACCES:
    "Ce champ vous permet de personnaliser l'URL du portail en choisissant un nom unique. Ce nom sera utilisé pour générer l'URL d'accès au portail. Veuillez utiliser uniquement des lettres, des chiffres et des tirets, sans espaces ni caractères spéciaux (/, &, ?, etc.) pour éviter d'altérer l'URL.",
  MENUTEST: 'Gestion des tests',
  IMPORT: {
    TITLE: 'Import de fichiers',
    TABLE: {
      DATE: "Date d'ajout",
      DESCRIPTION: 'Description',
      STATUS: 'Statut',
      NOM: 'Nom du fichier',
    },
    STATUS: {
      EN_COURS: 'En cours',
      TERMINE: 'Terminé',
      ERREUR: 'Erreur',
    },
    BUTTON: {
      IMPORT: 'Importer',
    },
  },
  MOTCLEINFO:
    'Ajoutez des mots-clés représentatifs (tags) pour votre entreprise (ex : informatique, start-up, secteur financier, innovation, télétravail). Ces mots-clés aideront à mieux identifier et catégoriser votre entreprise.',
  DETAILS: 'Détails',
  SOUSCRIREMAINTENANT: 'Souscrire maintenant',
  SOUSCRIREABONNEMENT: 'Souscrire à un abonnement',
  TEXTABONNEMENT0: 'à une de nos formules',
  TEXTABONNEMENT1: "Il semble que vous n'ayez pas d'abonnement actif. Souscrivez dès maintenant ",
  TEXTABONNEMENT2: "pour débloquer l'accès à tous nos modules et fonctionnalités exclusives.",
  PERONALISERPARAMETRE:
    'Personnalisez votre plateforme en activant ou désactivant vos modules ici, selon votre abonnement et vos besoins.',
  REFRESHAPPLICATION: 'Actualisez pour appliquer les modifications.',
  VOTRECREDITIA: 'Vos Crédits IA',
  VOTRECREDITSIGNATURE: 'Vos Crédits Signature',
  CREDITS: 'Crédits',
  MSGTITRESUGGESTION:
    "Ce sont des candidats existants dans la CVthèque qui sont éligibles pour cette offre d'emploi mais qui n'ont pas postulé",
  ECHECOPERATION: 'Opération échouée. Veuillez réessayer.',
  LIGNEINSERE: 'Ligne insérée',
  LIGNEREJETER: 'Ligne rejetée',
  MODETRAVAIL: 'Mode de travail',
  LOISIRS: 'Loisirs',
  SITE: 'Site',
  SERVICE: 'Service',
  TELEPHONEPRO: 'Téléphone professionnel',
  COMPETENCESPRES: 'Compétences',
  MSGPRES1: 'Ouvrir le chat',
  MSGPRES2: 'Suggérer un rendez-vous',

  AUCUNVIDEO: "Aucune vidéo n'est disponible pour le moment. Veuillez vérifier ultérieurement.",
  AUCUNPRESENTATION: "Aucune description n'est disponible pour le moment.",
  PRESENTATION: 'Présentation',
  CONTRAT: 'Contrat',
  DATEDEBUTCONTRAT: 'Date de début de contrat',
  DATEFINCONTRAT: 'Date de fin de contrat',
  FILTER: 'Filtrer',
  AUCUNMANAGER: 'Aucun manager trouvé',

  EQUIPE: 'Équipe',

  SELECTIONNEREQUIPE: 'Sélectionner une équipe',
  SELECTIONNERSITE: 'Sélectionner un site',
  FILTREREQUIPE: 'Filtrer les équipes',
  FILTRERSITE: 'Filtrer les sites',
  AUCUNEEQUIPETROUVEE: 'Aucune équipe trouvée',
  AUCUNSITETROUVE: 'Aucun site trouvé',

  MSGEQUIPEINFO:
    'Si vous n\'avez pas encore créé vos équipes, vous pouvez affecter ce collaborateur à l\'option "Non assigné". Une fois vos équipes créées, vous pourrez facilement affecter ce collaborateur à la bonne équipe.',
  REFUSCANDIDAT: 'Refuser le candidat',
  COPIER: 'Copier',
  COPIEREMAIL: "Copier l'adresse email",
  COPIEEMAILSUCCESS: 'Email copié avec succès',
  COPIEQUESTIONS: 'Copier les questions',
  COPIEQUESTIONSSUCCESS: 'Questions copiées avec succès',
  FERMER: 'Fermer',
  SUGGESTIONQUESTIONS: 'Voici une suggestion de 10 questions :',
  QUESTIONSPOSERLORSENTRETIEN: "Questions suggérées à poser aux candidats lors de l'entretien",
  ASSIGNEROFFRE: 'Assigner à une offre',
  EFFECTUERACTION: 'Effectuer une action',
  AUTRESCANDIDATS: 'Autres candidats',
  CANDIDATS: 'Candidats',
  TRIERPARDATE: 'Trier par date',
  PLUSRECENT: 'Plus récent',
  PLUSANCIEN: 'Plus ancien',

  AUCUNCANDIDATTROUVE: "Actuellement, aucun candidat n'a été trouvé.",
  VOICIQUESTION:'Voici une suggestion des questions :',
  CVSOUMIS: 'Ce CV a été soumis pour le poste de',
  PASPOSTEAPPLIQUE: 'Aucun poste appliqué',
  COMPTERENDUCV: 'Compte rendu du CV',
  APROPOS: 'À propos du candidat',
  NOMPRENOM: 'Nom complet',
  NATIONALITE: 'Nationalité',
  DATENAISSANCE: 'Date de naissance',
  ADRESSE: 'Adresse',
  EMAIL: 'Email',
  NUMTELEPHONE: 'Numéro de téléphone',
  NOM: 'Nom',
  PRENOM: 'Prénom',
  HISTORIQUE: 'Historique',
  CORRESPONDANCEAVEC: 'Correspondance avec le poste',
  RELANCERANALYSE: "Relancer l'analyse par critères de sélection",
  LANCERANALYSE: "Lancer l'analyse par critères de sélection",
  OFFREEMPLOI: "Offre d'emploi",
  ANALYSE: 'Analyse',
  CANDIDATNECORRESPONDPAS: "Le candidat ne correspond pas aux critères de sélection de l'offre d'emploi.",
  ANALYSEPOUSSE: "L'analyse poussée par IA est disponible uniquement pour le candidat rattaché à l'offre.",
  QUESTIONSSUGGEREES: 'Questions suggérées',
  COMPETENCES: 'Ses compétences',
  POSTESUGGERES: 'Postes suggérés',
  PASPOSTESUGGERE: 'Pas de postes suggérés',
  NOTES: 'Notes',
  EXPERIENCE: 'Expérience',
  FORMATION: 'Formation',
  CERTIFICATIONS: 'Certifications',
  LESDOCUMENTSDECANDIDAT: 'Documents du candidat',
  AJOUTERUNDOCUMENT: 'Ajouter un document',
  PROCESSUSDERECRUTEMENT: 'Processus de recrutement',
  ASSIGNERcetteoffre: 'Assigner à cette offre',
  SUGGERERCANDIDAT: 'Suggérer des candidats',
  LISTECANDIDATSSUGGERER: 'Liste des candidats suggérés',
  MESCANDIDATS: 'Mes candidats',
  CANDIDATDEJAASSIGNE: 'Ce candidat est déjà assigné à cette offre',
  CANDIDATNONASSIGNE: 'Assigner ce candidat à cette offre',
  RENSEIGNERFROMDOCUMENT: "Renseigner à partir d'un document",
  INFOPERSONELLE: 'Informations personnelles',
  LIEUNAISSANCE: 'Lieu de naissance',
  CODEPOSTAL: 'Code postal',
  COMPLEMENTADRESSE: "Complément d'adresse",
  COORDONNEES: 'Coordonnées',
  DOCUMENTIDENTITEVERSO: "Document d'identité verso",
  DOCUMENTIDENTITERECTO: "Document d'identité recto",
  DOCUMENTSIDENTITE: "Documents d'identité",
  NUMEROSECUSOCIALE: 'Numéro de sécurité sociale',
  VILLE: 'Ville',
  VOIRDOCEXISTANT: 'Voir document existant',
  REINITIALISER: 'Réinitialiser',
  AUTORISATIONSADMINISTRATIVES: 'Autorisations Administratives',
  MANAGER: 'Manager',
  EMPLOIETPOSITION: 'Emploi et Position',
  NOMDUCONTACTURGENT: "Nom du contact d'urgence",
  NUMERODUCONTACTURGENT: "Numéro du contact d'urgence",
  NOMBREDENFANTS: "Nombre d'enfants",
  VEUF: 'Veuf(ve)',
  DIVORCE: 'Divorcé(e)',
  MARIE: 'Marié(e)',
  CELIBATAIRE: 'Célibataire',
  SITUATIONFAMILIALE: 'Situation Familiale',
  SITUATIONFAMILIALEETURGENCE: 'Situation Familiale et Urgence',
  TELEPHONEPERSONNEL: 'Téléphone Personnel',
  TELEPHONEPROFESSIONNEL: 'Téléphone Professionnel',
  GENRE: 'Genre',
  HOMME: 'Homme',
  FEMME: 'Femme',
  AUTRE: 'Autre',
  INVALID_FILE_TYPE: 'Type de fichier invalide. Veuillez télécharger un fichier .xls ou .xlsx.',
  VEUUILERIMPORTER: 'Veuillez importer un fichier',
}
